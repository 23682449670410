<template>
  <div>
    <v-row class="px-4 pb-2" justify="end">
      <v-col class="align" cols="12">
        <label class="font-weight-bold mr-2 body-2">{{ $t(`label.searchDate`) }}</label>
        <div class="d-inline-block mx-1 transaction-page-search-date-field-box">
          <v-menu ref="menu" v-model="searchDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                color="secondary"
                :placeholder="$t(`label.from`)"
                append-icon="event"
                outlined
                class="input-field"
                hide-details
                dense
                readonly
                v-on="on"
                v-model="displaySearchDate"
              ></v-text-field>
            </template>
            <v-date-picker
              range
              v-model="searchDate"
              scrollable
              color="secondary"
              :locale="language"
              :min="minSelectableDate"
              :max="maxSelectableDate"
              :selected-items-text="`${searchDate[0]} - ${searchDate[1]}`"
              @change="reorderDateRangeValue"
            ></v-date-picker>
          </v-menu>
        </div>
        <v-btn tile class="mx-1" width="40" height="40" color="secondary" @click="searchMemberDetailReport()">
          <v-icon>search</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table class="transaction-table px-4" :headers="tableHeaders" :items="affiliateMembersDetail.list" hide-default-footer item-key="index" :locale="'zh-CN'">
      <template v-slot:body="{ items, isMobile }">
        <tbody>
          <template v-if="!isMobile">
            <tr v-for="item in items" :key="item.index">
              <td>{{ item.memberCode }}</td>
              <td>{{ item.loginCount }}</td>
              <td>{{ item.depositAmount | currency }}</td>
              <td>{{ item.withdrawalAmount | currency }}</td>
              <td>{{ item.bonusAmount | currency }}</td>
              <td>{{ item.adjustmentAmount | currency }}</td>
              <td>{{ item.winLoss | currency }}</td>
              <td>{{ item.turnover | currency }}</td>
            </tr>
            <tr v-if="affiliateMembersDetail.list.length > 0">
              <td class="font-weight-bold secondary--text">{{ $t(`label.summary`) }}</td>
              <td class="font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.loginCount }}</td>
              <td class="font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.depositAmount | currency }}</td>
              <td class="font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.withdrawalAmount | currency }}</td>
              <td class="font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.bonusAmount | currency }}</td>
              <td class="font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.adjustmentAmount | currency }}</td>
              <td class="font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.winLoss | currency }}</td>
              <td class="font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.turnover | currency }}</td>
            </tr>
            <tr v-if="items.length <= 0">
              <td :colspan="tableHeaders.length" class="text-center">{{ $t(`message.noDataAvaliable`) }}</td>
            </tr>
          </template>

          <template v-else>
            <tr v-for="item in items" :key="item.index">
              <td>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.memberCode') }}</v-col>
                  <v-col class="text-right">{{ item.memberCode }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.loginCount') }}</v-col>
                  <v-col class="text-right">{{ item.loginCount }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.depositAmount') }}</v-col>
                  <v-col class="text-right">{{ item.depositAmount | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.withdrawalAmount') }}</v-col>
                  <v-col class="text-right">{{ item.withdrawalAmount | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.bonusAmount') }}</v-col>
                  <v-col class="text-right">{{ item.bonusAmount | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.adjustmentAmount') }}</v-col>
                  <v-col class="text-right">{{ item.adjustmentAmount | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.winLoss') }}</v-col>
                  <v-col class="text-right">{{ item.winLoss | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.turnover') }}</v-col>
                  <v-col class="text-right">{{ item.turnover | currency }}</v-col>
                </v-row>
              </td>
            </tr>

            <tr v-if="affiliateMembersDetail.list.length > 0">
              <td>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text text-center">{{ $t('label.summary') }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text">{{ $t('label.loginCount') }}</v-col>
                  <v-col class="text-right font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.loginCount }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text">{{ $t('label.depositAmount') }}</v-col>
                  <v-col class="text-right font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.depositAmount | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text">{{ $t('label.withdrawalAmount') }}</v-col>
                  <v-col class="text-right font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.withdrawalAmount | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text">{{ $t('label.bonusAmount') }}</v-col>
                  <v-col class="text-right font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.bonusAmount | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text">{{ $t('label.adjustmentAmount') }}</v-col>
                  <v-col class="text-right font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.adjustmentAmount | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text">{{ $t('label.winLoss') }}</v-col>
                  <v-col class="text-right font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.winLoss | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text">{{ $t('label.turnover') }}</v-col>
                  <v-col class="text-right font-weight-bold secondary--text">{{ affiliateMembersDetail.pageSummary.turnover | currency }}</v-col>
                </v-row>
              </td>
            </tr>

            <tr v-if="items.length <= 0">
              <td class="text-center">{{ $t(`message.noDataAvaliable`) }}</td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>

    <v-row class="px-4" justify="end">
      <v-col class="align body-2" cols="12">
        <label>{{ `${$t(`label.lastUpdatedDate`)}: ` }}</label>
        <label>{{ affiliateMembersDetail.lastUpdatedDate | longDate }}</label>
      </v-col>
    </v-row>
    <v-row no-gutters class="px-4">
      <v-col cols="6" sm="4" lg="3">
        <div class="mx-2">
          <p class="body-2 ma-0 my-2 text-uppercase font-weight-bold secondary--text">{{ $t(`label.loginCount`) }}</p>
          <v-card tile class="px-2 py-1">{{ affiliateMembersDetail.recordSummary.loginCount }}</v-card>
        </div>
      </v-col>

      <v-col cols="6" sm="4" lg="3">
        <div class="mx-2 my-1">
          <p class="body-2 ma-0 my-2 text-uppercase font-weight-bold secondary--text">{{ $t(`label.depositAmount`) }}</p>
          <v-card tile class="px-2 py-1">{{ affiliateMembersDetail.recordSummary.depositAmount | currency }}</v-card>
        </div>
      </v-col>
      <v-col cols="6" sm="4" lg="3">
        <div class="mx-2 my-1">
          <p class="body-2 ma-0 my-2 text-uppercase font-weight-bold secondary--text">{{ $t(`label.withdrawalAmount`) }}</p>
          <v-card tile class="px-2 py-1">{{ affiliateMembersDetail.recordSummary.withdrawalAmount | currency }}</v-card>
        </div>
      </v-col>
      <v-col cols="6" sm="4" lg="3">
        <div class="mx-2 my-1">
          <p class="body-2 ma-0 my-2 text-uppercase font-weight-bold secondary--text">{{ $t(`label.bonusAmount`) }}</p>
          <v-card tile class="px-2 py-1">{{ affiliateMembersDetail.recordSummary.bonusAmount | currency }}</v-card>
        </div>
      </v-col>
      <v-col cols="6" sm="4" lg="3">
        <div class="mx-2 my-1">
          <p class="body-2 ma-0 my-2 text-uppercase font-weight-bold secondary--text">{{ $t(`label.adjustmentAmount`) }}</p>
          <v-card tile class="px-2 py-1">{{ affiliateMembersDetail.recordSummary.adjustmentAmount | currency }}</v-card>
        </div>
      </v-col>
      <v-col cols="6" sm="4" lg="3">
        <div class="mx-2 my-1">
          <p class="body-2 ma-0 my-2 text-uppercase font-weight-bold secondary--text">{{ $t(`label.winLoss`) }}</p>
          <v-card tile class="px-2 py-1">{{ affiliateMembersDetail.recordSummary.winLoss | currency }}</v-card>
        </div>
      </v-col>
      <v-col cols="6" sm="4" lg="3">
        <div class="mx-2 my-1">
          <p class="body-2 ma-0 my-2 text-uppercase font-weight-bold secondary--text">{{ $t(`label.turnover`) }}</p>
          <v-card tile class="px-2 py-1">{{ affiliateMembersDetail.recordSummary.turnover | currency }}</v-card>
        </div>
      </v-col>
    </v-row>
    <v-row class="px-4" justify="end">
      <v-col class="align body-2" cols="12">
        <v-pagination v-model="currentPage" :length="totalPage" @input="changePage" :total-visible="paginationPageLimit"></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { AFFILIATE_MEMBER_DETAIL_REPORT } from '@/store/affiliate.module'
import { locale, uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'
export default {
  name: 'affiliateMemberDetailReport',
  data: () => ({
    language: uiHelper.getLanguage(),
    minSelectableDate: new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().substr(0, 10),
    maxSelectableDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    searchDate: [new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substr(0, 10), new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)],
    searchDateMenu: false,
    currentPage: 1,
    totalPage: 1,
    paginationPageLimit: 5,
    tableHeaders: [
      {
        text: locale.getMessage('label.memberCode'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        width: '150',
        sortable: false
      },
      {
        text: locale.getMessage('label.loginCount'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        width: '180',
        value: 'loginCount',
        sortable: true
      },
      {
        text: locale.getMessage('label.depositAmount'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        width: '180',
        value: 'depositAmount',
        sortable: true
      },
      {
        text: locale.getMessage('label.withdrawalAmount'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        width: '180',
        value: 'withdrawalAmount',
        sortable: true
      },
      {
        text: locale.getMessage('label.bonusAmount'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        width: '180',
        value: 'bonusAmount',
        sortable: true
      },
      {
        text: locale.getMessage('label.adjustmentAmount'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        width: '180',
        value: 'adjustmentAmount',
        sortable: true
      },
      {
        text: locale.getMessage('label.winLoss'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        width: '180',
        value: 'winLoss',
        sortable: true
      },
      {
        text: locale.getMessage('label.turnover'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        width: '180',
        value: 'turnover',
        sortable: true
      }
    ],
    searchCriteria: {
      mode: 3,
      pageNumber: SHARED.DEFAULT_PAGE_NUMBER,
      pageSize: SHARED.DEFAULT_PAGE_SIZE,
      startDate: '',
      endDate: ''
    }
  }),
  computed: {
    displaySearchDate() {
      return this.searchDate.join(` ${locale.getMessage('label.to')} `)
    },

    affiliateMembersDetail() {
      return this.$store.state.affiliate.memberDetail
    },
    affiliateMembersDetailPagination() {
      return this.$store.state.affiliate.memberDetail.pagination
    }
  },
  watch: {
    affiliateMembersDetailPagination() {
      this.currentPage = this.$store.state.affiliate.memberDetail.pagination.pageNumber
      this.totalPage = this.$store.state.affiliate.memberDetail.pagination.lastPage
    }
  },
  created() {
    this.getMemberDetailReport()
  },
  methods: {
    reorderDateRangeValue(x) {
      if (new Date(x[0]) >= new Date(x[1])) {
        let startDate = x[1]
        let endDate = x[0]
        this.searchDate[0] = startDate
        this.searchDate[1] = endDate
      }
    },
    getMemberDetailReport() {
      let obj = {
        mode: this.searchCriteria.mode,
        size: this.searchCriteria.pageSize,
        page: this.searchCriteria.pageNumber,
        sort: 'report_date DESC',
        startDate: this.searchDate[0],
        endDate: this.searchDate[1]
      }

      this.$store.dispatch(`${AFFILIATE_MEMBER_DETAIL_REPORT}`, { obj })
    },
    changePage(targetPage) {
      this.searchCriteria.pageNumber = targetPage
      this.getMemberDetailReport()
    },
    searchMemberDetailReport() {
      this.changePage(1)
    }
  }
}
</script>

<style lang="scss">
.transaction-page-search-date-field-box {
  width: 280px;
}
</style>
